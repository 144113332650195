import React from 'react'
import './ContactsInfo.scss'


const ContactsInfo = () => {

  return (
    <div className='info'>
        <h2 className='info-title'>Contact us</h2>
        <div className='info-wrap'>
            <div className='info-wrap-inform'>
                <div className='info-wrap-inform-wrapper'>
                    <p className='info-span'>Address</p>
                    <p className='info-discr'>7301 Yellowstone Blvd, Forest Hills, NY 11375</p>
                </div>
                <div className='info-wrap-inform-wrapper'>
                    <p className='info-span'>Phone</p>
                    <p className='info-discr'>(718) 544-4024</p>
                </div>
                <div className='info-wrap-inform-wrapper'>
                    <p className='info-span'>Hours</p>
                    <div className='info-wrapper'>
                        <p className='info-wrapper-discr'>Monday,<br /> Tuesday,<br /> Wednesday - Sunday</p>
                        <p className='info-wrapper-discr'>12:00AM - 8:45PM<br /> Closed <br /> 12:00AM - 8:45PM</p>
                    </div>
                </div>
            </div>
            <div className='info-map'>
            </div>
        </div>
    </div>
  )
}

export default ContactsInfo