import React from 'react'
import './About.scss'

const About = () => {
  return (
    <div className='about'>
        <div className='about-wrapper'>
            <div className='about-wrapper-wrap'>
                <p className='about-wrapper-wrap-name'>VillageGrill</p>
                <div className='about-wrapper-wrap-div'></div>
            </div>
            <h2 className='about-wrapper-title'>About us</h2>
            <p className='about-wrapper-discr'>At Village Grill, we’re more than a restaurant – we’re a family. Our food, steeped in tradition and authenticity, showcases a delightful assortment of Greek favorites. Unique, homemade daily specials every day. Reflecting on the changing seasons and our chef’s creativity, ensuring there’s always something new and delightful for you to discover. </p>
        </div>
        <div className='about-image'></div>
        <div className='about-stats'>
          <div className='about-stats-wrapper wrapper'>
            <h3>5.0/5</h3>
            <p>Yelp</p>
          </div>
          <div className='about-stats-wrapper wrapper'>
            <h3>4.8/5</h3>
            <p>UberEATS</p>
          </div>
          <div className='about-stats-wrapper wrapper'>
            <h3>4.5/5</h3>
            <p>Seamless</p>
          </div>
        </div>
    </div>
  )
}

export default About