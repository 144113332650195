import React from 'react';
import './Menu.scss'

import menu from '../../assets/imgs/menu0.png'
import menu1 from '../../assets/imgs/menu1.png'
import qr from '../../assets/imgs/Menu2QR.jpg'
const Menu = () => {
  return (
    <div className='menu'>
        <div className='submenu'>
            <img src={menu}/>
            <img src={menu1}/>
        </div>
        <div>
        <img src={qr}/>
      </div>

    </div>
  )
}

export default Menu