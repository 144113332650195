import React from 'react'
import './CateringBanner.scss'

const CateringBanner = () => {
  return (
    <div className='banner-catering'>
        <h1 className='banner-catering-title'>Catering</h1>
    </div>
  )
}

export default CateringBanner