import React from 'react'
import './EndTitle.scss'

const EndTitle = () => {
  return (
    <div className='end-title'>
        We’ve been serving the Forest Hills community and beyond for the last 12 years, even during the worst times of pandemic.
    </div>
  )
}

export default EndTitle