import React from 'react'
import './Banner.scss'

const Banner = () => {
  return (
    <div className='banner'>
        <div>
            <div className='banner-wrapper'>
                <div className='banner-wrapper-div'></div>
                <p className='banner-wrapper-name'>Family-run Greek restaurant</p>
                <div className='banner-wrapper-div'></div>
            </div>
            <div className='banner-wrapper'>
                <h1 className='banner-wrapper-title'>Experience the warmth of a Greek family</h1>
            </div>
            <div className='banner-wrapper'>
                <p className='banner-wrapper-discr'>Every day homemade specials.</p>
            </div>
        </div>
    </div>
  )
}

export default Banner