import React, { useEffect } from 'react'
import About from '../../components/About/About'
import Banner from '../../components/Banner/Banner'
import EndTitle from '../../components/EndTitle/EndTitle'
import Food from '../../components/FoodSection/Food'
import TeamSection from '../../components/TeamSection/TeamSection'

const HomePage = () => {
    

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
        <div>
          <Banner />
          <About /> 
          <TeamSection />
          <Food /> 
          <EndTitle />
        </div>
  )
}

export default HomePage