import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {addContent} from '../../store/profileContentSlice'
import Dropdown from 'react-bootstrap/Dropdown';

import './ProfileNav.scss'


const ProfileNav = () => {

    const content = useSelector(state => state.profileContent.content)
    const isAuth = useSelector(state => state.auth.isAuth)

    const dispatch = useDispatch()

    const profileNavArray = [
        {
            title: 'Account',
            content: 'account',
            to: 'account'
        },
        {
            title: 'MyOrders',
            content: 'orders',
            to: 'orders'
        },
        {
            title: 'Favourite',
            content: 'favourite',
            to: 'favourite'
        },
        {
            title: 'Settings',
            content: 'settings',
            to: 'settings'
        }
    ]


    return (
      <>
        <div className='nav'>
            <p className='nav-title'>Profile</p>
            {
                profileNavArray.map((item, index) =>
                    <NavLink key={index} to={item.to}
                             className={` ${content === item.content ? 'active' : ''} nav-item`}
                             onClick={isAuth ? () => dispatch(addContent(item.content)) : ''}>{item.title}</NavLink>)
            }
        </div>
      </>
    )
}

export default ProfileNav