import React from 'react'
import Person from './Person/Person.jsx'
import './TeamSection.scss'
import person from '../../assets/imgs/about_us_5.jpg'
const persons = [
    {
        img: person,
        name: 'Dina',
        job: 'Chef and Owner',
        discr: 'Dina is the heart and soul of Village Grill. A passionate chef and dedicated business owner, her journey with Greek cuisine began in her grandmother’s kitchen, where she discovered the magic of combining fresh ingredients to create dishes that sing with flavor. As a chef, Dina takes immense pride in creating traditional Greek dishes with a modern twist. \n' +
            'As the owner of Village Grill, Dina’s warm and welcoming personality sets the tone for the restaurant. She believes in creating an environment where everyone feels like part of the family. \n'
    },
]

const TeamSection = () => {
  return (
    <div className='team'>
        <h2 className='team-title'>Our Family</h2>
        <div className='team-persons'>
           {persons.map((item, index) => <Person key={index} item={item} />)}
        </div>
    </div>
  )
}

export default TeamSection