import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthService from '../services/AuthService'


export const auth = createAsyncThunk('auth/fetchAuthStatus', async (params) => {
  const { email: username, password } = params
  try {
    const { data } = await AuthService.login(username, password);
    localStorage.setItem('isAuth', true)
    localStorage.setItem('token', data.access)
    localStorage.setItem('refresh', data.refresh)
    return data
  } catch (error) {
    return error
  }
})

export const registr = createAsyncThunk('auth/fetchRegStatus', async (params) => {
  const { email, password } = params
  try {
    const res = await AuthService.registration(email, password);
    return res
  } catch (error) {
    console.log(error);
    return error.response
  }
})

export const activate = createAsyncThunk('auth/activateAccount', async (params) => {
  const { token } = params
  const { data } = await AuthService.activate(token);
  localStorage.setItem('token', data.access)
  localStorage.setItem('refresh', data.refresh)
  return data
})

export const refresh = createAsyncThunk('auth/refreshToken', async () => {
  const refresh = localStorage.getItem('refresh')
  const { data } = await AuthService.refresh(refresh);
  localStorage.setItem('token', data.access)
  localStorage.setItem('refresh', data.refresh)
  return data
})

export const logout = createAsyncThunk('auth/logoutAccount', async () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
  localStorage.removeItem('isAuth')
})

export const changePassword = createAsyncThunk('auth/changePassword', async (params) => {
  const { data } = await AuthService.changePassword(params);
  return data
})

const initialState = {
  user: {},
  userData: {},
  isAuth: false,
  token: '',
  status: '',
  statusCode: 0
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action) {
      state.isAuth = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    }
  },
  extraReducers: {
    [auth.fulfilled]: (state, action) => {
      state.isAuth = true
      state.token = action.payload.access
      state.userData = action.payload
    },
    [registr.fulfilled]: (state, action) => {
      
    },
    [activate.fulfilled]: (state, action) => {
      state.token = action.payload.access
      if (action.payload.access) {
        state.isAuth = true
      }
    },
    [logout.fulfilled]: (state, action) => {
      state.token = ''
      state.isAuth = false
      state.user = {}
    },
    [changePassword.fulfilled]: (state, action) => {
      console.log(action.payload);
    }
  }
})

export default authSlice.reducer
export const {
  setAuth,
  setUser,
  setToken
} = authSlice.actions