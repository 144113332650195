import React from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { changePassword } from '../../store/authSlice';

const Settings = () => {
  const {
    handleSubmit,
    formState: { errors },
    trigger,
    register,
  } = useForm();

  const dispatch = useDispatch()
  const user = useSelector(state => state.profileContent.user)
  const onhandleSubmit = (data) => {
    const params = {
      old_password: data.password,
      password: data.newPassword,
      password2: data.newPassword
    }
    dispatch(changePassword({params, id: user.id}))
  }

  return (
    <div className='form'>
      <div className='form-wrapper'>
        <h5 className='form-wrapper-title'>Change your profile</h5>
        <form onSubmit={handleSubmit(onhandleSubmit)}>
          <div>
            <div className='form-wrapper-div'>
              <label className='form-wrapper-div-email'>E-mail</label>
              <input
                className='form-wrapper-div-input'
                id="email"
                name="email"
                type='email'
                required={true}
                {...register("email", {
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  }
                })}
                error={Boolean(errors.email)}
                onKeyUp={() => { trigger("email") }}
              ></input>
              {errors.email && (
                <small className="text-danger">{errors.email.message}</small>
              )}
            </div>
            <div className='form-wrapper-div'>
              <label className='form-wrapper-div-password'>Password</label>
              <input
                name='password'
                id="password"
                type='password'
                autoComplete='off'
                className='form-wrapper-div-input'
                required={true}
                {...register("password", {
                  required: "You must specify a password",
                  pattern: {
                    value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                    message: "Password should contain at least one number and one    special character"
                  }
                })}
                onKeyUp={() => { trigger("password") }}
                error={Boolean(errors.password)}
              ></input>
              {errors.password && (
                <small className="text-danger">{errors.password.message}</small>
              )}
            </div>
            <div className='form-wrapper-div'>
              <label className='form-wrapper-div-password'>New password</label>
              <input
                name='newPassword'
                id="newPassword"
                type='password'
                autoComplete='off'
                className='form-wrapper-div-input'
                required={true}
                {...register("newPassword", {
                  required: "You must specify a password",
                  pattern: {
                    value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                    message: "Password should contain at least one number and one    special character"
                  }
                })}
                onKeyUp={() => { trigger("newPassword") }}
                error={Boolean(errors.newPassword)}
              ></input>
              {errors.password && (
                <small className="text-danger">{errors.password.message}</small>
              )}
            </div>
            <div className='form-wrapper-button'>
              <button className='form-wrapper-button-btn'>Change password</button>
            </div>
          </div>
        </form>
      </div>
      <div className='form-image'>
      </div>
    </div>
  );
}

export default Settings