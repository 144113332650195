import React from 'react'
import { useDispatch } from 'react-redux'
import { addToOrder, decrementOrder } from '../../store/menuSlice'
import './Btns.scss'

const Btns = ({ params, style, item, count }) => {
    const dispatch = useDispatch()
    const locationArray = window.location.pathname.split('/')
    const location = locationArray[locationArray.length - 1]

    return (
        <>
            <div style={style} className='btns'>
                <button className='btns-remove' onClick={() => dispatch(decrementOrder({ count, item }))}>{ location !== 'orders' ? '-' : '' }</button>
                <button className='btns-count'>{count}</button>
                <button className='btns-add' onClick={() => dispatch(addToOrder(params))}>{ location !== 'orders' ? '+' : '' }</button>
            </div>
        </>

    )
}

export default Btns