import { configureStore } from "@reduxjs/toolkit";
import profileContentReducer from "./profileContentSlice";
import menuContentReducer from "./menuSlice"
import authReducer from './authSlice'
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";


const persistConfig = {
    key: 'root',
    version: 1,
    storage
}

const reducer = combineReducers({
    profileContent: profileContentReducer,
    menuContent: menuContentReducer,
    auth: authReducer
})

const persistedReducer = persistReducer(persistConfig, reducer)


export default configureStore({
    reducer: persistedReducer
})