import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Header.scss'
import basket from '../../assets/icons/basket.svg'
import logo from '../../assets/logo.svg'
import profile from '../../assets/icons/profile.svg'
import MobileHeader from '../MobileHeader/MobileHeader'
import { useDispatch, useSelector } from 'react-redux'
import { refresh } from '../../store/authSlice'
import btn from '../../assets/imgs/order_online.png'
const Header = () => {

  const [active, setActive] = useState(false)
  const mobile = window.innerWidth <= 980

  const isAuth = useSelector(state => state.auth.isAuth)
  return (
    <>
      <div className="header-mobile">
        <NavLink to='/' id='logo'><img className='header-nav-logo' src={logo} alt="" /></NavLink>
        <div className={`header-burger ${active ? 'active' : ''}`} onClick={() => setActive(!active)}>
          <span></span>
        </div>
      </div>

      <div className={`header ${active ? 'active' : ''}`}>
        <div className='header-nav'>
          <NavLink
            className='header-nav-item' to='/'
            onClick={() => setActive(false)}
          >
            Home
          </NavLink>
          <div
            className='header-nav-menu header-nav-item'
            onClick={() => setActive(false)}
          >
            <NavLink to='/menu'>
              Menu
              <img src={basket} alt="" />
            </NavLink>

          </div>
          <NavLink to='/' id='logo'><img className='header-nav-logo' src={logo} alt="" /></NavLink>
          <NavLink
            className='header-nav-item' to='/catering'
            onClick={() => setActive(false)}
          >
            Catering
          </NavLink>
          <NavLink
            className='header-nav-item' to='/contacts'
            onClick={() => setActive(false)}
          >
            Contacts
          </NavLink>
          <NavLink
            className='header-nav-item' to='https://order.online/business/village-grill-561998'
            onClick={() => setActive(false)}
          >
            <img src={btn}/>
          </NavLink>
          {/*<NavLink className='header-nav-item header-login' to='profile/account' onClick={() => setActive(false)}*/}
          {/*>*/}
          {/*  {isAuth ? "Profile" : "Log in"}*/}
          {/*  <img src={profile} alt="" />*/}
          {/*</NavLink>*/}
        </div>
      </div>
    </>

  )
}

export default Header