import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'

const RequireAuth = ({ children }) => {
    const { location } = useLocation()
    const isAuth = useSelector(state => state.auth.isAuth)

    if (!isAuth) {
        return <Navigate to='/profile/login' state={{ from: location }} />
    }
        
    return children
}

export default RequireAuth