import $api from "../api";

export default class FoodService {
    static async getFood() {
        return $api.get('/catalog/')
    }

    static async addToOrder({params}) {
        return $api.post('/order/', params)
    }

    static async getOrder() {
        return $api.get('/order/')
    }

    static async decrementOrder(params) {
        return $api.put(`/order/item/${params.item.id}/`, {count : params.count - 1})
    }

    static async deleteItem(params) {
        return $api.delete(`/order/item/${params}/`)
    }

    static async deleteOrder(params) {
        return $api.delete(`/order/${params}/`)
    }

    static async payment(params) {
        return $api.post(`/payment/`, {order : params})
    }
}