import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import './Footer.scss'
import {
    BrowserRouter as Router,
    Switch,
    useLocation,
} from 'react-router-dom';

const Footer = () => {
  let location = useLocation();
  let show = location.pathname !== '/contacts'
  return (
    <div className='footer'>
        <div>
            <NavLink to='/'>
                <img className='footer-logo' src={logo} alt="" />
            </NavLink>
        </div>
        {show ? (
        <div className='footer-info'>
            <div className='footer-item'>
                <p className='title'>Address</p>
                <p className='discr'>7301 Yellowstone Blvd,<br/> Forest Hills, NY 11375</p>
            </div>
            <div className='footer-item'>
                <p className='title'>Phone</p>
                <p className='discr'>(718) 544-4024</p>
            </div>
            <div className='footer-item'>
                <p className='title'>Hours</p>
                <ul className='footer-hours'>
                    <li>
                        <div>Mon</div>
                        <div>12:00- 8:45&nbsp;PM</div></li>
                    <li>
                        <div>Tue</div>
                        <div>Closed</div>
                    </li>
                    <li>
                        <div>Wed-Sun</div>
                        <div>12:00- 8:45&nbsp;PM</div>
                    </li>
                </ul>


            </div>
        </div>
        ):(
                <div className='footer-nav'>
                    <NavLink to='/'>Home</NavLink>
                    <NavLink to='/menu'>Menu</NavLink>
                    <NavLink to='/catering'>Catering</NavLink>
                    <NavLink to='/contacts'>Contacts</NavLink>
                </div>
            )}

    </div>
  )
}

export default Footer