import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import favourite from '../../../../assets/icons/favourite.svg'
import favourite1 from '../../../../assets/icons/favourite1.svg'
import { addToOrder } from '../../../../store/menuSlice'
import { addToFavourite, setModalItem } from '../../../../store/profileContentSlice'
import Btns from '../../../ThreeBtns/Btns';
import './MenuItem.scss'

const MenuItem = ({ item, order }) => {

    const favouriteArray = useSelector(state => state.profileContent.favourite)
    const favouriteCheck = favouriteArray.some(obj => obj.id === item.id)
    const itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)

    const items = itemsInBasket?.items
    const basketCheck = items?.filter(obj => obj.food.id === item.id)
    const dispatch = useDispatch()
    let count = 0
    if (basketCheck !== undefined) {
        count = basketCheck[0]?.count
    }
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])


    let params

    if (itemsInBasket?.length !== 0) {
        params = {
            "id": item.id,
            "order": itemsInBasket?.id
        }
    } else {
        params = {
            "id": item.id
        }
    }
    /**
     * TODO: сделай заглушку для картинок заказа, если не приходит картинка
     */

    return (
        <div className='item'>
            <div className='item-card'>
                <div className='item-card-wrap'>
                    {item?.photo ? <img className='item-card-wrap-image' src={`https://mamasgreek.com${item.photo}`} alt="" /> : ''}
                    {!order ? <img onClick={() => dispatch(addToFavourite(item))} className='item-card-wrap-svg' src={favouriteCheck ? favourite1 : favourite} alt="" />
                        : ''}
                </div>
                <div className={`item-card-wrapper ${item.photo ? '' : 'no-photo' }`}>
                    <span className='item-card-wrapper-name'>{item.name}</span>
                    <div className='item-card-wrapper-bottom'>
                        <span className='item-card-wrapper-bottom-price'>$ {item?.price}</span>
                        {!basketCheck?.length ?
                            <button onClick={() => dispatch(addToOrder(params))} className='item-card-wrapper-bottom-button'>+ Add</button> :
                            <Btns item={basketCheck[0]} params={params} count={count} />
                        }
                    </div>
                </div>
                <div onClick={() => dispatch(setModalItem(item))} className='item-card-absolute'></div>
            </div>
        </div>
    )
}

export default MenuItem