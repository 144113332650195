import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal';

import './OrderModal.scss'
import {setModalItem} from '../../store/profileContentSlice';
import AntSwitch from '../AntSwitch/AntSwitch';
import ControlledCheckbox from '../Checkbox/Checkbox';
import MultilineTextFields from '../TextField/TextField';
import { addToOrder, setIngredientsArray, setOptionsObj } from '../../store/menuSlice';
import Close from './Close.svg'

const mobile = window.innerWidth <= 560

const customStyles = {
    content: {
        top: mobile ? '55%' : '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        borderRadius: '20px',
        height: '90vh',
        width: mobile ? '100vw' : '80vw',
        maxWidth: '900px'

    },
};

const OrderModal = () => {

    const dispatch = useDispatch()
    const itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)

    const item = useSelector(state => state.profileContent.modalItem)
    const textField = useSelector(state => state.menuContent.textField)
    const optionsObj = useSelector(state => state.menuContent.optionsObj)
    const ingredientsArray = useSelector(state => state.menuContent.ingredientsArray)
    const closeModal = () => {
        dispatch(setModalItem(''))
        dispatch(setOptionsObj(''))
        dispatch(setIngredientsArray(0))
    }
    let params

    if (itemsInBasket?.length !== 0) {
        params = {
            id: item.id,
            order: itemsInBasket?.id,
            options: optionsObj,
            ingredients: ingredientsArray,
            comment: textField
        }
    } else {
        params = {
            "id": item.id,
            options: optionsObj,
            ingredients: ingredientsArray,
            comment: textField
        }
    }

    const handleClick = () => {
        dispatch(addToOrder(params))
        dispatch(setModalItem(''))
        dispatch(setOptionsObj(''))
        dispatch(setIngredientsArray(0))
    }

    const totalIngredientsPrice = ingredientsArray?.reduce((total, item) => total + item?.price, 0)
    const totalItemPrice = item.price + totalIngredientsPrice

    return (
        <div>
            <Modal
                isOpen={Boolean(item)}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <div className='modal-wrapper'>
                    <img src={Close} onClick={closeModal} className='modal-close'/>
                    <img className='modal-wrapper-img' src={`https://mamasgreek.com${item.photo}`} alt="" />
                    <div className='modal-wrapper-content'>
                        <h2 className='modal-wrapper-content-title'>{item.name}</h2>
                        <p className='modal-wrapper-content-description'>{item.description}</p>
                        {item?.options?.length ?
                            <div>
                                <p className='modal-wrapper-content-p'>Choose a meat preparation</p>
                                <div className='modal-wrapper-content-options'>
                                    {item?.options?.map((items, index) =>
                                        <AntSwitch key={index} options={items} />
                                    )}
                                </div>
                            </div> : ''}
                        {item?.ingredients?.length ? <div>
                            <p className='modal-wrapper-content-p'>Would you like add-ons?</p>
                            <div className='modal-wrapper-content-ingedients'>
                                {item?.ingredients?.map((item, index) =>
                                    <ControlledCheckbox key={index} ingredients={item} />)}
                            </div>
                        </div> : ''}
                        <p className='modal-wrapper-content-p'>Comments</p>
                        <MultilineTextFields />
                        <div className='modal-wrapper-content-btns'>
                            <div className='modal-wrapper-content-btns-buttons'>
                                <button onClick={handleClick} className='modal-wrapper-content-btns-buttons-button'>Order</button>
                                <button onClick={closeModal} className='modal-wrapper-content-btns-buttons-order'>Cancel</button>
                            </div>
                            <p>$ {totalItemPrice.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default OrderModal
