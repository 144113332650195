import React from 'react'
import MenuItem from './MenuItem/MenuItem'
import './MenuSection.scss'

const MenuSection = ({items}) => {
  return (
    <div className='section'>
        {items.map((item, index) =>
            <MenuItem key={index} item={item} />
            )}
    </div>
  )
}

export default MenuSection