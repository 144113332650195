import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import ProfileNav from '../../components/ProfileNav/ProfileNav'
import './Profile.scss'
import ProfileNavMobile from "../../components/ProfileNavMobile/ProfileNavMobile";

const Profile = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className='profile'>
      {window.innerWidth >= 980  ? <ProfileNav /> : <ProfileNavMobile/>}
        <Outlet />
    </div>
  )
}

export default Profile