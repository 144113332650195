import React, { useEffect } from 'react'
import CateringBanner from '../../components/CateringBanner/CateringBanner'
import CateringInfo from '../../components/CateringInfo/CateringInfo'

const Catering = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <CateringBanner />
      <CateringInfo />
    </div>
  )
}

export default Catering