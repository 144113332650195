import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { toggleOrderModal } from '../../store/menuSlice'
import OrderFooterModal from '../OrderFooterModal/OrderFooterModal'
import './FooterBasket.scss'

const FooterBasket = () => {

    const setOrderModal =  useSelector(state => state.menuContent.setOrderModal)
    const dispatch = useDispatch()
    const itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)
    const totalCount = itemsInBasket.items.reduce((total, item) => total + item.count, 0)


    return (
        <div className='footer-basket'>
          <div className='footer-basket-wrap'>
            <p onClick={() => dispatch(toggleOrderModal())} className='footer-basket-count'>{totalCount} order{itemsInBasket.items.length > 1 ? 's' : ''}</p>
            <span className='footer-basket-sum'>${itemsInBasket.price}</span>
          </div>
            <NavLink to="/order" className='footer-basket-button'>Order</NavLink>
            {/* {setOrderModal ? <OrderFooterModal /> : ''} */}
        </div>
    )
}

export default FooterBasket