import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { setIngredientsArray } from "../../store/menuSlice";

import './Checkbox.scss'

export default function ControlledCheckbox({ ingredients }) {

    const ingredientsArray = useSelector(state => state.menuContent.ingredientsArray)

    const dispatch = useDispatch()
    const handleChange = () => {
        dispatch(setIngredientsArray({id : ingredients.id, price: ingredients.price}))
    };
    const checked = ingredientsArray?.some(item => item.id == ingredients.id)   
    return (
        <div className="checkbox">
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
            />
            <p>{ingredients.name} + ${ingredients.price}</p>
        </div>
    );
}
