import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { activate } from '../../store/authSlice'

const Activate = () => {

    const { token } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(activate({token})).then( item => item.payload.access ? navigate('/profile/account') : console.log(item))
    }, [])



    return (
    <div className='activatePage'>
        Аккаунт активирован!
        <Navigate to='/profile/login'>На главную</Navigate>
    </div>
  )
}

export default Activate