import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import FoodService from '../services/FoodService';

export const fetchFood = createAsyncThunk(
  'sectionContent/fetchFoodStatus',
  async () => {
    const { data } = await FoodService.getFood();
    return data.results
  }
)

export const addToOrder = createAsyncThunk(
  'sectionContent/addToOrder',
  async (params) => {
    const { data } = await FoodService.addToOrder({params});
    return data
  }
)

export const getOrder = createAsyncThunk(
  'sectionContent/getOrder',
  async () => {
    const { data } = await FoodService.getOrder();
    return data.results
  }
)

export const decrementOrder = createAsyncThunk(
  'sectionContent/getOrder',
  async (params) => {
    const { data } = await FoodService.decrementOrder(params);
    return data
  }
)

export const deleteItem = createAsyncThunk(
  'sectionContent/deleteItem',
  async (params) => {
    const { data } = await FoodService.deleteItem(params);
    return data
  }
)

export const deleteOrder = createAsyncThunk(
  'sectionContent/deleteOrder',
  async (params) => {
    const { data } = await FoodService.deleteOrder(params);
    return data
  }
)

export const payment = createAsyncThunk(
  'sectionContent/payment',
  async (params) => {
    const { data } = await FoodService.payment(params);
    return data
  }
)

const menuSlice = createSlice({
  name: 'sectionContent',
  initialState: {
    section: 'burgers',
    setOrderModal: false,
    optionsObj: {},
    ingredientsArray: [],
    status: '',
    items: [],
    itemsInBasket: {},
    accountOrders: [],
    orderNumber: 0,
    textField: ''
  },
  reducers: {
    addSection(state, action) {
      state.section = action.payload
    },
    toggleOrderModal(state) {
      state.setOrderModal = !state.setOrderModal
    },
    setOptionsObj(state, action) {
        state.optionsObj = action.payload
    },
    setIngredientsArray(state, action) {
      if(state.ingredientsArray.some(item => item.id == action.payload.id)) {
        state.ingredientsArray = state.ingredientsArray.filter(item => item.id !== action.payload.id)
      } else {
        if(action.payload === 0) {
          state.ingredientsArray = []
        } else {
          state.ingredientsArray.push(action.payload)
        }
      }
    }, 
    setTextField(state, action){
      state.textField = action.payload
    },
    setClearItemsInBasket(state, action) {
      state.itemsInBasket = {}
    }
  },
  extraReducers: {
    [fetchFood.pending]: (state) => {
      state.status = 'loading'
      state.items = []
    },
    [fetchFood.fulfilled]: (state, action) => {
      state.items = action.payload
      state.status = 'success'
    },
    [fetchFood.rejected]: (state) => {
      state.status = 'error'
      state.items = []
    },
    [addToOrder.fulfilled]: (state, action) => {
      state.itemsInBasket = action.payload
      state.orderNumber = action.payload.id
    },
    [getOrder.fulfilled]: (state, action) => {
      state.accountOrders = action.payload
    },
    [decrementOrder.fulfilled]: (state, action) => {
      state.itemsInBasket = action.payload
    },
    [deleteItem.fulfilled]: (state, action) => {
      state.itemsInBasket = action.payload
    },
    [deleteOrder.fulfilled]: (state, action) => {
      state.itemsInBasket = action.payload
    },
    [payment.fulfilled]: (state, action) => {
      console.log(action.payload);
    }
  }
})

export default menuSlice.reducer
export const { 
  addSection,
  toggleOrderModal,
  setOptionsObj,
  setIngredientsArray,
  setTextField,
  setClearItemsInBasket
 } = menuSlice.actions