import React, { useEffect } from 'react'

import './Pay.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const Pay = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const item = useSelector(state => state.profileContent.modalItem)
  const orderNumber = useSelector(state => state.menuContent.orderNumber)

useEffect(() => {
  window.scroll(0,0)
}, [])

  return (
    <div className='payment'>
       <h1>Your order #{orderNumber} was accepted</h1>
    </div>
  )
}

export default Pay