import React from 'react'
import './ContactsBanners.scss'

const ContactsBanner = () => {
  return (
    <div className='banner'>
        <h1 className='banner-title'>We’re eager to hear from you! Whether you have questions, feedback, or wish to inquire about our catering services, please don’t hesitate to contact us – we’re just a message or call away.</h1>
    </div>
  )
}

export default ContactsBanner