import React from 'react'
import './RegisterSuccess.scss'

const RegisterSuccess = () => {
  return (
    <div className='registerWrapper'>
        <p>Thank you! We got your information</p>
        <p>Please, check your email</p>
    </div>
  )
}

export default RegisterSuccess