import React from 'react'
import Sliders from './Slider/Slider'
import './Food.scss'

const Food = () => {
  return (
    <div className='food'>
        <h2 className='food-title'>Food</h2>
        <Sliders />
    </div>
  )
}

export default Food