import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { payment, setClearItemsInBasket } from '../../store/menuSlice';
import './OrderForm.scss'
import {useNavigate} from "react-router-dom";

const OrderForm = () => {

    const [tabs, setTabs] = useState('minutes')

    const basket = useSelector(state => state.profileContent.basket)
    const {
        handleSubmit,
        trigger,
        register,
    } = useForm();
    const orderNumber = useSelector(state => state.menuContent.orderNumber)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onhandleSubmit = (data) => {
        dispatch(payment(orderNumber)).then(()=>{navigate('/payment')})
        dispatch(setClearItemsInBasket())
    }

    const itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)

    const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30] 
    const time = ['00:00', "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]


    return (
        <div className='orderForm'>
            <form>
                <div className='orderForm-wrapper'>
                    <h5 className='orderForm-wrapper-title'>Your contacts</h5>
                    <div className='orderForm-wrapper-div'>
                        <div className='orderForm-wrapper-div-item'>
                            <label className='orderForm-wrapper-div-item-label'>Your name</label>
                            <input
                                className='orderForm-wrapper-div-item-input'
                                id="name"
                                name="name"
                                type='text'
                                placeholder='Name'
                                {...register("name", {
                                    pattern: {
                                        value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                                        message: "Invalid name  ",
                                    }
                                })}
                                onKeyUp={() => { trigger("name") }}
                            ></input>
                        </div>
                        <div className='orderForm-wrapper-div-item'>
                            <label className='orderForm-wrapper-div-item-label'>Phone number</label>
                            <input
                                className='orderForm-wrapper-div-item-input'
                                id="number"
                                name="number"
                                type='text'
                                placeholder='+7...'
                                {...register("number", {
                                    pattern: {
                                        value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                                        message: "Invalid name  ",
                                    }
                                })}
                                onKeyUp={() => { trigger("number") }}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className='orderForm-wrapper'>
                    <h5 className='orderForm-wrapper-title'>Address</h5>
                    <div className='orderForm-wrapper-div'>
                        <div className='orderForm-wrapper-div-item'>
                            <label className='orderForm-wrapper-div-item-label'>Address</label>
                            <input
                                className='orderForm-wrapper-div-item-input'
                                id="address"
                                name="address"
                                type='text'
                                placeholder='Street'
                                {...register("address", {
                                    pattern: {
                                        value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                                        message: "Invalid address  ",
                                    }
                                })}
                                onKeyUp={() => { trigger("address") }}
                            ></input>
                        </div>
                        <div className='orderForm-wrapper-div-item'>
                            <label className='orderForm-wrapper-div-item-label'>Flat</label>
                            <input
                                className='orderForm-wrapper-div-item-input'
                                id="flat"
                                name="flat"
                                type='text'
                                placeholder='0'
                                {...register("flat", {
                                    pattern: {
                                        value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                                        message: "Invalid flat  ",
                                    }
                                })}
                                onKeyUp={() => { trigger("flat") }}
                            ></input>
                        </div>
                        <div className='orderForm-wrapper-div-item'>
                            <label className='orderForm-wrapper-div-item-label'>Flour</label>
                            <input
                                className='orderForm-wrapper-div-item-input'
                                id="flour"
                                name="flour"
                                type='text'
                                placeholder='0'
                                {...register("flour", {
                                    pattern: {
                                        value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                                        message: "Invalid flour  ",
                                    }
                                })}
                                onKeyUp={() => { trigger("flour") }}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className='orderForm-wrapper'>
                    <h5 className='orderForm-wrapper-title'>Order time</h5>
                    <div className='orderForm-wrapper-div'>
                        <div onClick={() => setTabs('minutes')} className={`orderForm-wrapper-div-button ${tabs === 'minutes' ? 'activeTabs' : ''}`}>70-80 min</div>
                        <div onClick={() => setTabs('time')} className={`orderForm-wrapper-div-button ${tabs === 'time' ? 'activeTabs' : ''}`}>for time</div>
                    </div>
                </div>
                {tabs === 'time' ?
                    <div className='orderForm-wrapper'>
                        <select className='orderForm-wrapper-select' {...register("day")}>
                            {days.map(item => <option value={item}>{item}</option>)}
                        </select>
                        <select className='orderForm-wrapper-select' {...register("time")}>
                            {time.map(item => <option value={item}>{item}</option>)}
                        </select>
                    </div> :
                    ''}
                <div onClick={handleSubmit(onhandleSubmit)} style={{ "display": "flex", "justifyContent": "flex-end" }} className='orderForm-wrapper'>
                    <button className='orderForm-wrapper-button'>Pay {itemsInBasket.price.toFixed(2)}$</button>
                </div>
            </form>
        </div>
    )
}

export default OrderForm