import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import MobileHeader from './components/MobileHeader/MobileHeader'

const Layout = () => {
  return (
    <div>
        <Header />
        <div className='outlet'>
            <Outlet />
        </div>
        <Footer />
    </div>
  )
}

export default Layout