import React from 'react'
import { NavLink } from 'react-router-dom'
import './CateringInfo.scss'

const CateringInfo = () => {


  return (
    <div className='catering'>
        <div className='catering-wrapper'>
            <div className='catering-wrapper-wrap'>
                <p className='catering-wrapper-wrap-name'>VillageGrill</p>
                <div className='catering-wrapper-wrap-div'></div>
            </div>
            <h2 className='catering-wrapper-title'></h2>
            <p className='catering-wrapper-discr'>Whether you're planning an intimate dinner party, a corporate event, or a large wedding celebration, our team will work closely with you to create a menu that suits your needs and delights your guests. We also cater to a variety of dietary preferences, including vegetarian, vegan, and gluten-free options, ensuring everyone can enjoy our delectable Greek cuisine.<br/>
                When you choose Village grill Catering Services, we do more than just deliver food. We bring the spirit of Greek hospitality to your event.</p>
        </div>
    </div>
  )
}

export default CateringInfo