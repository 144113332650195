import React from 'react'
import './Person.scss'

const Person = ({item}) => {
  return (
    <div className='person'>
        <img className='person-image' src={item.img} alt="" />
        <div className='person-container'>
            <h3 className='person-name'>{item.name}</h3>
            <p className='person-job'>{item.job}</p>
            <p className='person-discr'>{item.discr}</p>
        </div>

    </div>
  )
}

export default Person