import React from 'react'
import MenuSection from './MenuSection/MenuSection';
import './MenuContent.scss'

const MenuContent = ({items}) => {


  return (
    <div className='content'>
        {items?.map((item, index) =>
            <div key={index} className='content-wrapper' id={item?.id}>
                <h2 className='content-wrapper-title'>{item?.name}</h2>
                <MenuSection items={item?.foods} />
            </div>)}
    </div>
  )
}

export default MenuContent