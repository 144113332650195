import { Routes, Route } from 'react-router-dom'
import HomePage from "./pages/HomePage/HomePage";
import Catering from './pages/Catering/Catering';
import Contacts from './pages/Contacts/Contacts';
import Order from './pages/Order/Order';
import NotFound from './pages/NotFound/NotFound';
import Menu from './pages/Menu/Menu';
import Profile from './pages/Profile/Profile';
import Pay from './pages/Pay/Pay'

import Layout from './layout';
import Auth from './components/Auth/Auth';
import Registration from './components/Registration/Registration';
import AccountProfile from './components/AccountProfile/AccountProfile';
import MyOrders from './components/MyOrders/MyOrders';
import Favourites from './components/Favourites/Favourites';
import Settings from './components/Settings/Settings';
import RequireAuth from './hoc/RequireAuth';
import Activate from './components/Activate/Activate';
import RegisterSuccess from './pages/RegisterSuccess/RegisterSuccess';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='catering' element={<Catering />} />
          <Route path='contacts' element={<Contacts />} />
          <Route path='order' element={<Order />} />
          <Route path='menu' element={<Menu />} />
          <Route path='payment' element={<Pay />} />
          <Route path='auth/confirm_email/:token' element={<Activate />} />
          <Route path='registration-success' element={<RegisterSuccess />} />
          <Route path='profile/*' element={<Profile />} >
            <Route path='login' element={<Auth />} />
            <Route path='registration' element={<Registration />} />
            <Route path='account' element={
              <RequireAuth>
                <AccountProfile />
              </RequireAuth>
            } />
            <Route path='orders' element={
              <RequireAuth>
                <MyOrders />
              </RequireAuth>
            } />
            <Route path='favourite' element={
              <RequireAuth>
                <Favourites />
              </RequireAuth>
            } />
            <Route path='settings' element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            } />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
