import React from 'react'
import './Registration.scss'
import { useForm } from 'react-hook-form'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registr } from '../../store/authSlice';
import { Alert } from '@mui/material';

const Registration = ({ setForm }) => {
  const {
    handleSubmit,
    formState: { errors },
    trigger,
    register,
  } = useForm();

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onhandleSubmit = (data) => {
    dispatch(registr(data)).then(item => item.payload.statusText ? navigate('/registration-success') : console.log(item))
  }

  const status = useSelector(state => state.auth.status)
  const statusCode = useSelector(state => state.auth.statusCode)


  return (
    <div className='form'>
      <div className='form-wrapper'>
        <h5 className='form-wrapper-title'>Register</h5>
        <form onSubmit={handleSubmit(onhandleSubmit)}>
          <div>
            <div className='form-wrapper-div'>
              <label className='form-wrapper-div-email'>E-mail*</label>
              <input
                className='form-wrapper-div-input'
                id="email"
                name="email"
                type='email'
                required={true}
                {...register("email", {
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  }
                })}
                error={Boolean(errors.email)}
                onKeyUp={() => { trigger("email") }}
              ></input>
              {errors.email && (
                <small className="text-danger">{errors.email.message}</small>
              )}
            </div>
            <div className='form-wrapper-div'>
              <label className='form-wrapper-div-password'>Password*</label>
              <input
                name='password'
                id="password"
                type='password'
                autoComplete='off'
                className='form-wrapper-div-input'
                required={true}
                {...register("password", {
                  required: "You must specify a password",
                  pattern: {
                    value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                    message: "Password should contain at least one number and one    special character"
                  }
                })}
                onKeyUp={() => { trigger("password") }}
                error={Boolean(errors.password)}
              ></input>
              {errors.password && (
                <small className="text-danger">{errors.password.message}</small>
              )}
            </div>
            <div className='form-wrapper-div'>
              <label className='form-wrapper-div-password'>Repeat password*</label>
              <input
                name='repeatPassword'
                id="repeatPassword"
                type='password'
                autoComplete='off'
                className='form-wrapper-div-input'
                required={true}
                {...register("repeatPassword", {
                  required: "You must specify a password",
                  pattern: {
                    value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                    message: "Password should contain at least one number and one    special character"
                  }
                })}
                onKeyUp={() => { trigger("password") }}
                error={Boolean(errors.password)}
              ></input>
              {errors.password && (
                <small className="text-danger">{errors.password.message}</small>
              )}
            </div>
            <div className='form-wrapper-check'>
              <input name="acceptRemember" type="checkbox" {...register('acceptRemember')} id="acceptRemember" className={`form-wrapper-check-checkbox ${errors.acceptRemember ? 'is-invalid' : ''}`} />
              <label htmlFor="acceptRemember" className="form-wrapper-check-label">Remember me</label>
            </div>
            <div className='form-wrapper-button'>
              <button className='form-wrapper-button-btn'>Register</button>
            </div>
          </div>
        </form>
        {/*<Link to='/profile/login'>*/}
        {/*  <button className='form-wrapper-reg'>Log in</button>*/}
        {/*</Link>*/}

        {statusCode !== 0 ? <Alert style={{ marginTop: "20px" }} severity={statusCode === 200 ? "success" : "error"}>{status}</Alert> : ''}
      </div>
      <div className='form-image'>
      </div>
    </div >
  );
}

export default Registration 