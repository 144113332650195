import React from 'react'
import MyOrderSection from './MyOrderSection/MyOrderSection'
import './MyOrdersContent.scss'

const MyOrdersContent = ({items}) => {

  return (
    <div className='myOrders'>
        <h2 className='myOrders-title'>My Orders</h2>
        {items?.map(item => <MyOrderSection item={item} /> )}
    </div>
  )
}

export default MyOrdersContent