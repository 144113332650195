import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {addContent} from '../../store/profileContentSlice'

import './ProfileNavMobile.scss'


const ProfileNavMobile = () => {

    const content = useSelector(state => state.profileContent.content)
    const isAuth = useSelector(state => state.auth.isAuth)

    const dispatch = useDispatch()

    const profileNavArray = [
        {
            title: 'Account',
            content: 'account',
            to: 'account'
        },
        {
            title: 'MyOrders',
            content: 'orders',
            to: 'orders'
        },
        {
            title: 'Favourite',
            content: 'favourite',
            to: 'favourite'
        },
        {
            title: 'Settings',
            content: 'settings',
            to: 'settings'
        }
    ]

    return (
        <div className='nav-mobile'>
            <ul className='menu'>
                <li className='menu-title'>
                    <span>Account</span>
                    <ul>
                        {
                            profileNavArray.map((item, index) =>
                               <li>
                                  <NavLink key={index} to={item.to}
                                           className={`nav-item-mobile`}
                                           onClick={isAuth ? () => dispatch(addContent(item.content)) : ''}>{item.title}
                                  </NavLink>
                               </li>)
                        }
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ProfileNavMobile