import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { logout, refresh } from '../../store/authSlice';
import { getData, updateProfileData } from '../../store/profileContentSlice';

import './AccountProfile.scss'

const AccountProfile = () => {
  
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(getData())
    dispatch(refresh())
  }, [])

  const user = useSelector(state => state.profileContent.user)
  const userId = useSelector(state => state.profileContent.userId)
  const {
    handleSubmit,
    formState: { errors },
    trigger,
    register,    
  } = useForm({
    defaultValues: {
      name: user.name,
      phone: user.phone,
      email: user.email,
      address: user.address
    }
  });

  const onhandleSubmit = (data) => {
    const params = {userId, ...data}
    dispatch(updateProfileData(params))
  }

  return (
    <div className='accountform'>
      <div className='accountform-wrapper'>
        <h5 className='accountform-wrapper-title'>Welcome</h5>
        <form>
          <div className='column-wrap'>
            <div className='left-col'>
              <div className='accountform-wrapper-div'>
                <label className='accountform-wrapper-div-email'>Your name</label>
                <input
                  className='accountform-wrapper-div-input'
                  id="name"
                  name="name"
                  type='text'
                  placeholder='Name'
                  {...register("name", {
                    pattern: {
                      value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                      message: "Invalid name  ",
                    }
                  })}
                  onKeyUp={() => { trigger("name") }}
                ></input>
              </div>
              <div className='accountform-wrapper-div'>
                <label className='accountform-wrapper-div-password'>Phone</label>
                <input
                  name='phone'
                  id="phone"
                  type='text'
                  placeholder='+7...'
                  className='accountform-wrapper-div-input'
                  {...register("phone", {
                    pattern: {
                      value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                    }
                  })}
                  onKeyUp={() => { trigger("phone") }}
                ></input>
              </div>
              <div className='accountform-wrapper-div'>
                <label className='accountform-wrapper-div-password'>E-mail</label>
                <input
                  name='email'
                  id="email"
                  type='text'
                  placeholder='E-mail'
                  className='accountform-wrapper-div-input'
                  {...register("email", {
                    pattern: {
                      value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                    }
                  })}
                  onKeyUp={() => { trigger("email") }}
                ></input>
              </div>
            </div>
            <div className='right-col'>
              <div className='accountform-wrapper-image'></div>
              <div className='accountform-wrapper-div'>
                <label className='accountform-wrapper-div-password'>Address</label>
                <input
                  name='address'
                  id="address"
                  type='text'
                  placeholder='Street'
                  className='accountform-wrapper-div-input'
                  {...register("address", {
                    pattern: {
                      value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){    1,})(?!.*\\s).{8,}$',
                    }
                  })}
                  onKeyUp={() => { trigger("address") }}
                  error={Boolean(errors.password)}
                ></input>
              </div>
            </div>
          </div>
          <div className='accountform-wrapper-button'>
              <button onClick={handleSubmit(onhandleSubmit)} className='accountform-wrapper-button-btn'>Save</button>
              <button onClick={() => dispatch(logout())} className='accountform-wrapper-button-logout'>Log out</button>
            </div>
        </form>
      </div>
    </div>
  )
}

export default AccountProfile