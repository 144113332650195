import React from 'react'
import MenuItem from '../../MenuContent/MenuSection/MenuItem/MenuItem';
import './MyOrderSection.scss'

const MyOrderSection = ({ item }) => {

    return (
        <div>
            <div className='mySection'>
                <div className='mySection-wrapper'>
                    <p className='mySection-wrapper-time'>Order # {item.id}</p>
                    <div style={{"display": "flex"}}>
                    {item.items.map(item =>
                        <MenuItem order item={item.food} />
                    )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyOrderSection