import React from 'react'
import { useSelector } from 'react-redux'
import FooterBasket from '../FooterBasket/FooterBasket'
import MenuContent from '../MenuContent/MenuContent'
import OrderModal from '../OrderModal/OrderModal'

const Favourites = () => {

  const favourite = useSelector(state => state.profileContent.favourite)
  const name = 'Favourite'
  const items = [{ name, foods: [...favourite] }]
  const itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)
  const item = useSelector(state => state.profileContent.modalItem)

  if(!items[0].foods.length) {
    return <div className='empty'>You don't have favourites yet</div>
  }

  return (
    <div>
      <MenuContent items={items} />
      {item ? <OrderModal /> : ''}
      {itemsInBasket?.items?.length && !item ? <FooterBasket /> : null}
    </div>
  )
}

export default Favourites