import $api from "../api";

export default class AuthService {
    static async login(username, password) {
        return $api.post('/login/', {username, password})
    }

    static async registration(email, password) {
        return $api.post('/registration/', {email, password})
    }

    static async activate(token) {
        return $api.post('/activate/', {value : token})
    }    

    static async refresh(token) {
        return $api.post('/token/refresh/', {refresh : token})
    }    

    static async logout() {
        return $api.post('/logout/')
    }

    static async changePassword(params) {
        const {old_password, password, password2} = params.params
        return $api.put(`/change_password/${params.id}/`, {old_password, password, password2})
    }
}