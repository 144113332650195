import React, { useEffect } from 'react'
import ContactsBanner from '../../components/ContactsBanner/ContactsBanner'
import ContactsInfo from '../../components/ContactsInfo/ContactsInfo'

const Contacts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <ContactsBanner />
      <ContactsInfo />
    </div>
  )
}

export default Contacts