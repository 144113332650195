import React from 'react'
import Btns from '../ThreeBtns/Btns'

import Delete from '../../assets/icons/delete.svg'

import './OrderItem.scss'
import { useDispatch, useSelector } from 'react-redux'
import { deleteItem } from '../../store/menuSlice'

const OrderItem = ({ item }) => {
  const dispatch = useDispatch()
  const count = item.count
  const totalIngredientsPrice = item?.ingredients?.reduce((total, item) => total + item.price, 0)
  let itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)
  let totalItemPrice
  if (item.ingredients) {
    totalItemPrice = (item.food.price * count) + (totalIngredientsPrice * count)
  } else {
    totalItemPrice = (item.food.price * count)
  }

  const params = {
    "id": item.food.id,
    "order": itemsInBasket.id
  }


  console.log(item);
  return (
    <div className='orders'>
      <div className='orders-all'>
        <div className="orders-delete">
          <img className='orders-delete-icon' onClick={() => dispatch(deleteItem(item.id))} src={Delete} alt="" />
        </div>
        <div className='orders-all-item'>
          <div className='orders-all-item-div'>
            {item.food.photo ? <img className='orders-all-item-div-image' src={`https://mamasgreek.com${item?.food?.photo}`} alt="" /> : ''}
            <div className='orders-all-item-div-options'>
              <p className='orders-all-item-div-options-title'>{item?.food?.name}</p>
              <p className='orders-all-item-div-options-item'>{item?.options?.name}</p>
              {item?.ingredients && item?.ingredients?.map(item => <div className='orders-all-item-div-options-wrapper'>
                <p className='orders-all-item-div-options-wrapper-item'>{item?.name}</p>
                <p className='orders-all-item-div-options-wrapper-item'>+${item?.price}</p>
              </div>)}
            </div>
          </div>
          <div className='orders-all-item-div orders-all-item-div-btns'>
            <Btns params={params} count={count} item={item} />
            <div className='orders-all-item-div-div'>
              <p className='orders-all-item-div-div-price'>${totalItemPrice.toFixed(2)}</p>
              <p className='orders-all-item-div-div-weight'>250г</p>
            </div>
          </div>
        </div>
        <p>{item.comment}</p>
      </div>
    </div>
  )
}

export default OrderItem