import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHistoryData } from '../../store/profileContentSlice'
import FooterBasket from '../FooterBasket/FooterBasket'
import MyOrdersContent from '../MyOrdersContent/MyOrdersContent'
import OrderModal from '../OrderModal/OrderModal'
import './MyOrders.scss'

const MyOrders = () => {

  const dispatch = useDispatch()
  const myOrders = useSelector(state => state.profileContent.historyOrders)
  const itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)
  const item = useSelector(state => state.profileContent.modalItem)
  useEffect(() => {
    dispatch(getHistoryData())
  }, [])

  if (!myOrders.length) {
    return <div className='empty'>You don't have orders yet</div>
  }
  return (
    <div>
      <MyOrdersContent items={myOrders} />
      {item ? <OrderModal /> : ''}
      {itemsInBasket?.items?.length && !item ? <FooterBasket /> : null}
    </div>
  )
}

export default MyOrders