import React from 'react'
import { useNavigate } from 'react-router-dom'
import './GoBack.scss'
import ArrowBack from '../../assets/icons/arrow-back.svg'

const GoBack = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1)

  return (
    <div onClick={goBack}>
        <img src={ArrowBack} alt="" />
    </div>
  )
}

export default GoBack