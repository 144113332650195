import React, { useEffect } from 'react'
import GoBack from '../../components/GoBackButton/GoBack';
import Trash from '../../assets/icons/trash.svg'
import { removeAllBasket } from '../../store/profileContentSlice';

import './Order.scss'
import { useDispatch, useSelector } from 'react-redux';
import OrderItem from '../../components/OrderItem/OrderItem';
import { useNavigate } from 'react-router-dom';
import OrderForm from '../../components/OrderForm/OrderForm';
import { deleteOrder, getOrder } from '../../store/menuSlice';

const Order = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const itemsInBasket = useSelector(state => state.menuContent.itemsInBasket)
  const orderNumber = useSelector(state => state.menuContent.orderNumber)
  const item = useSelector(state => state.profileContent.modalItem)

  useEffect(() => {
    if (item) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [item])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  if (!itemsInBasket || !itemsInBasket?.items?.length) {
    navigate("/menu/")
  }

  console.log(itemsInBasket);

  return (
    <div className='order'>
      <GoBack />
      <div className='order-wrapper'>
        <h2 className='order-wrapper-title'>{`Your order #${itemsInBasket?.id}`}</h2>
        <div onClick={() => dispatch(deleteOrder(orderNumber))} className='order-wrapper-delete'>
          <h3 className='order-wrapper-delete-title'>Delete all</h3>
          <img className='order-wrapper-delete-icon' src={Trash} alt="" />
        </div>
      </div>
      {itemsInBasket.items?.map(item =>
        <OrderItem item={item} />
      )}
      <div className='order-total'>
        {itemsInBasket?.comment ? <p>Comment: {itemsInBasket.comment} </p> : ''}
        <p className='order-total-price'>For all: {itemsInBasket.price}$</p>
      </div>
      <OrderForm />
    </div>
  )
}

export default Order