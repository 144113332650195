import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { setTextField } from '../../store/menuSlice';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
    },
},
}));

export default function MultilineTextFields() {
    const classes = useStyles();
    const dispatch = useDispatch()

    const handleChange = event => {
        dispatch(setTextField(event.target.value))
    };

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    minRows="4"
                    maxRows="6"
                    variant="outlined"
                    onChange={handleChange}
                />
            </div>
        </form>
    );
}