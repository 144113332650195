import React from 'react'
import Slider from 'react-slick'
import slide1 from '../../../assets/imgs/slide1.png'
import greek_salad from '../../../assets/imgs/greek_salad.png'
import slide3 from '../../../assets/imgs/gyro.png'
import slide4 from '../../../assets/imgs/salad_bowl.png'
import slide5 from '../../../assets/imgs/burgers.png'
import slide6 from '../../../assets/imgs/slide2.png'
import slide7 from '../../../assets/imgs/gyro.png'
import slide8 from '../../../assets/imgs/salad_bowl.png'
import slide9 from '../../../assets/imgs/burgers.png'
import slide10 from '../../../assets/imgs/slide4.png'
import slide11 from '../../../assets/imgs/slide1.png'
import slide12 from '../../../assets/imgs/salad_bowl.png'
import './Slider.scss'


const slides = [
  {img: slide5, title: 'Burgers'},
  {img: greek_salad, title: 'Greek Salad'},
  {img: slide3, title: 'Gyro'},
  {img: slide4, title: 'Salad Bowl'},
  {img: slide5, title: 'Burgers'},
  {img: greek_salad, title: 'Greek Salad'},
  {img: slide7, title: 'Gyro'},
  {img: slide4, title: 'Salad Bowl'},
  {img: slide9, title: 'Burgers'},
  {img: greek_salad, title: 'Greek Salad'},
  {img: slide7, title: 'Gyro'},
  {img: slide4, title: 'Salad Bowl'},
]

const Sliders = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  /**
   * TODO: если картинки не одинакового соотношения, тогда в слайдере текст будет скакать
   */
  return (
    <div className='slider'>
        <Slider {...settings}>
          {slides.map(item => 
            <div>
              <img className='slider-img' src={item.img} alt="" />
              <h2 className='slider-title'>{item.title}</h2>
            </div>
            )}
        </Slider>
      </div>
  )
}

export default Sliders