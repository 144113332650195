import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UserService from '../services/UserService';

export const getData = createAsyncThunk('profileContent/fetchUserData', async () => {
  const { data } = await UserService.fetchUserData();
  return data
})

export const updateProfileData = createAsyncThunk('profileContent/updateProfileData', async (params) => {
  try {
    const { data } = await UserService.updateUserData(params);
    return data
  } catch (error) {
    return error.response.statusText
  }
})

export const getHistoryData = createAsyncThunk('profileContent/getHistoryData', async () => {
  const { data } = await UserService.getHistoryData();
  return data
})

const profileContentSlice = createSlice({
  name: 'profileContent',
  initialState: {
    user: {},
    content: 'account',
    modalItem: {},
    favourite: [],
    basket: [],
    historyOrders: [],
    totalPrice: 0,
    userId: 0
  },
  reducers: {
    addContent(state, action) {
      state.content = action.payload
    },
    addToFavourite(state, action) {
      if (state.favourite.some(item => item.id === action.payload.id)) {
        state.favourite = state.favourite.filter(item => item.id !== action.payload.id)
      } else {
        state.favourite.push(action.payload)
      }
    },
    addToBasket(state, action) {
      const findItem = state.basket.find(obj => obj.id === action.payload.id)

      if (findItem) {
        findItem.count++
      } else {
        state.basket.push({
          ...action.payload,
          count: 1
        })
      }
      state.totalPrice = state.basket.reduce((total, item) => total + (item.price * item.count), 0).toFixed(2)

    },
    removeFromBasket(state, action) {
      const findItem = state.basket.find(item => item.id === action.payload.id)
      if (findItem) {
        findItem.count--
      }
      if (findItem.count <= 0) {
        state.basket = state.basket.filter(item => item.id !== action.payload.id)
      }
    },
    deleteItemFromBasket(state, action) {
      state.basket = state.basket.filter(item => item.id !== action.payload.id)
    },
    setModalItem(state, action) {
      console.log(action);
      state.modalItem = action.payload
    },
    removeAllBasket(state, action) {
      state.basket = []
    }
  },
  extraReducers: {
    [getData.fulfilled]: (state, action) => {
      state.user = action.payload
      state.userId = action.payload.id 
    },
    [updateProfileData.fulfilled]: (state, action) => {
      state.user = action.payload
      state.userId = action.payload.id 
    },
    [getHistoryData.fulfilled]: (state, action) => {
      state.historyOrders = action.payload.results
    },
  },
})

export default profileContentSlice.reducer
export const {
  addContent,
  addToFavourite,
  addToBasket,
  removeFromBasket,
  setModalItem,
  removeAllBasket,
  deleteItemFromBasket
} = profileContentSlice.actions