import $api from "../api";

export default class UserService {
    static fetchUserData() {
        return $api.get('/user/')
    }

    static updateUserData(params) {
        const { address, email, phone, name, userId } = params
        return $api.put(`/user/${userId}/`, {address, email, phone, name})
    }

    static getHistoryData() {
        return $api.get(`/history/`)
    }
}